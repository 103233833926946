import React from "react";
import { graphql } from "gatsby";
import Container from "@material-ui/core/Container";
import Contact from "../components/contact";
import Content from "../components/content";
import Layout from "../components/layout";
import Spacer from "../components/spacer";
import SEO from "../components/seo";

const Components = ({ data }) => {
  const fm = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={fm.seoTitle} description={fm.seoDescription} />

      <Spacer h={100} />

      <Container>
        <div style={{ maxWidth: 720, margin: "auto" }}>
          <Content data={fm.content_md1} />
        </div>
      </Container>

      <div style={{ marginTop: 320 }}></div>

      <Contact />
    </Layout>
  );
};

export default Components;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        seoTitle
        seoDescription
        page_title
        content_md1
      }
    }
  }
`;
